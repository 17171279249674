.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.prompt-thin {
  font-family: "Prompt", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.prompt-extralight {
  font-family: "Prompt", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.prompt-light {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.prompt-medium {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.prompt-semibold {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-bold {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.prompt-extrabold {
  font-family: "Prompt", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.prompt-black {
  font-family: "Prompt", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.prompt-thin-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.prompt-extralight-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.prompt-light-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.prompt-regular-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.prompt-medium-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.prompt-semibold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.prompt-bold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.prompt-extrabold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.prompt-black-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.selected-item {
  background-color: #f0f0f0;
  color: #1976d2;
}

.selected-item:hover {
  background-color: #e0e0e0;
}

.list-item:hover {
  background-color: #f5f5f5;
}

.text-ellipsis {
  display: block;
  width: "100px";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-builder--date-picker {
  input[type="date"] {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    font-style: normal;
    background-color: "red";
  }
}

.react-datepicker--clear-icon {
  font-size: 18px;
}

.put-to-top {
  z-index: 9999 !important; /* Set the desired z-index */
}

.put-to-second-top {
  z-index: 7000 !important; /* Set the desired z-index */
}

.put-to-third-top {
  z-index: 5000 !important; /* Set the desired z-index */
}
