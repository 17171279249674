.ripple {
  position: relative;
  display: block;
  margin: 0 auto;
  background: var(
    --color
  ); /* Replace with the desired color variable or value */
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 20px 45px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  border: 0;
  overflow: hidden;
  border: 5px white solid;
  cursor: pointer;
}

.ripple:active,
.ripple:visited,
.ripple:focus {
  outline: 0;
  border: 0;
  color: "theme.secondary";
}
.ripple:hover {
  color: "red";
}

.ripple span {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  transform: scale(0);
  position: absolute;
  opacity: 1;
}

.rippleAnimation {
  animation: ripple 0.6s linear;
}

@keyframes ripple {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes wave {
  0% {
    background-position: -200% 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.wave-animation {
  background: linear-gradient(
    180deg,
    rgba(255, 235, 59, 0.3),
    rgba(255, 235, 59, 0.7),
    rgba(255, 235, 59, 0.3)
  );
  background-size: 200% 100%;
  animation: wave 0.5 ease-in-out;
}

.copied-row {
  animation: fadeInSlide 0.5s ease-out;
}

.empty-compy {
  transform: scale(0);
}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeAndSlide {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
