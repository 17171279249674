/* styles.css */
.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  width: 200px;
}

.sidebar-item {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.sidebar-item:hover {
  background-color: #ddd;
}

.sidebar-item.selected {
  background-color: #1976d2;
  color: white;
}

.prompt {
  font-family: "prompt";
}

.react-datepicker--time-only {
  padding: 5px 10px;
}

* {
  font-family: "prompt" !important;
}
