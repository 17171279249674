.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .item {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .app {
    text-align: center;
    margin: 20px;
  }